import Vue from 'vue';
import gql from 'graphql-tag';
import ClientNav from '@/components/Clients/ClientNav';
import Footer from '@/components/Shared/Footer/Footer';
import apollo from '@/services/apollo';
import Loader from '@/components/Shared/Loader/Loader';

const Services = Vue.extend({
  data() {
    return {
      loading: true,
      services: [],
    };
  },

  methods: {
    loadServices() {
      const query = gql`
        {
          getOfferings{
            enabled
            description
            id
            name
            price
          }
        }
      `;

      apollo.query({ query })
        .then(({ data }) => {
          this.services = data?.getOfferings || [];
        })
        .catch((err) => {
          console.log('Error loading available services', err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },

  mounted() {
    this.loadServices();
  },

  render() {
    return (
      <div>
        <ClientNav />
        <div class="services">
          {
            this.loading
              ? <Loader />
              : (
                <h1>Offerings</h1>
              )
          }
        </div>
        <Footer />
      </div>
    );
  },
});

export default Services;
